import React, { useEffect, useRef } from "react";
import 'react-modern-drawer/dist/index.css';
import Chart from "chart.js/auto";
import { presetColors } from '../../../libs/colours.js';
import { results_tranlsated } from '../../../libs/results_translated.js';

const ComparePanel = ({
    isComparing,
    handleCompareClose,
    longstandingGeoJSONRef,
    originalGeoJSONforComparingRef,
    selectedMetric,
    comparingLandtypeSelectedOption,
    selectedYearEditing
}) => {
    const chartRef = useRef(null);
    const lineChartRef = useRef(null);

    const initChart = (chartRef, chartId, datasets) => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById(chartId);
        if (ctx) {
            chartRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: datasets.labels,
                    datasets: datasets.datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        title: { display: false },
                        legend: { display: false }
                    },
                    scales: {
                        y: {
                            beginAtZero: true
                        },
                        x: {
                            display: false, // Ensure x-axis is displayed
                            stacked: false, // Ensure bars are not stacked
                            grid: {
                                display: false
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 0
                            },
                            barPercentage: 0.5, // Match bar width configuration
                            categoryPercentage: 0.1 // Match spacing configuration
                        }
                    }
                }
            });
        }
    };

    const initChartline = (chartRef, chartId, datasets) => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        const ctx = document.getElementById(chartId);
        if (ctx) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: datasets.labels,
                    datasets: datasets.datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: { title: { display: false }, legend: { display: false } },
                    scales: { y: { beginAtZero: true }, x: { display: true } }
                }
            });
        }
    };

    const parseGeoJSON = (geojson, selectedYearEditing) => {
        const cumulativeDataMap = new Map();
        geojson.features.forEach(feature => {
            const ukhab = feature.feature_data_simple_timeseries.HabitatType[selectedYearEditing];
            let value;
            if (feature.feature_data_simple_timeseries[selectedMetric] !== undefined) {
                value = feature.feature_data_simple_timeseries[selectedMetric][selectedYearEditing];
            }
            cumulativeDataMap.set(ukhab, (cumulativeDataMap.get(ukhab) || 0) + value);
        });
        const sortedData = Array.from(cumulativeDataMap.entries()).sort((a, b) => b[1] - a[1]);
        const labels = sortedData.map(entry => entry[0]);
        const data = sortedData.map(entry => entry[1]);
        return { labels, data };
    };

    // Function to transform results_translated into chart-friendly format
    const transformResultsToData3 = (results) => {
        // Extract land types (labels) and values (data points) from results
        const labels = Object.keys(results);
        const data = labels.map(label => results[label][selectedMetric]); // Multiply each value by 10
        return {
            labels, // Land types
            data // Corresponding values
        };
    };

    const years = [
        "2024-01-01T00:00:00+00:00",
        "2027-01-01T00:00:00+00:00",
        "2029-01-01T00:00:00+00:00",
        "2031-01-01T00:00:00+00:00"
    ];

    const getCumulativeDataOverYears = (geojson, landType) => {
        const cumulativeData = [];
        years.forEach((year) => {
            let yearlyTotal = 0;
            geojson.features.forEach(feature => {
                const habitatType = feature.feature_data_simple_timeseries.HabitatType[year];
                if (habitatType === landType) {
                    const metricData = feature.feature_data_simple_timeseries[selectedMetric];
                    if (metricData !== undefined && metricData[year] !== undefined) {
                        yearlyTotal += metricData[year];
                    }
                }
            });
            cumulativeData.push(yearlyTotal);
        });
        return cumulativeData;
    };

    const getCumulativeDataOverYearsAll = (geojson) => {
        const cumulativeData = [];
        years.forEach((year) => {
            let yearlyTotal = 0;
            geojson.features.forEach(feature => {
                const metricData = feature.feature_data_simple_timeseries[selectedMetric];
                if (metricData !== undefined && metricData[year] !== undefined) {
                    yearlyTotal += metricData[year];
                }
            });
            cumulativeData.push(yearlyTotal);
        });
        return cumulativeData;
    };

    useEffect(() => {
        if (longstandingGeoJSONRef.current && originalGeoJSONforComparingRef.current) {
            const data1 = parseGeoJSON(longstandingGeoJSONRef.current, selectedYearEditing);
            const data2 = parseGeoJSON(originalGeoJSONforComparingRef.current, selectedYearEditing);
            const data3 = transformResultsToData3(results_tranlsated); // Transform results_tranlsated
            const highlightColor = "#FFD700"; // Highlight color for selected landtype

            // Function to convert a color to its greyscale equivalent
            const toGreyscale = (color) => {
                console.log(color)
                if (color === undefined) {
                    return "#000000";
                } else {
                    // Extract the RGB components
                    const r = parseInt(color.slice(1, 3), 16);
                    const g = parseInt(color.slice(3, 5), 16);
                    const b = parseInt(color.slice(5, 7), 16);
                    // Calculate the greyscale value
                    const grey = Math.round((r + g + b) / 3);
                    // Convert the greyscale value back to a hex color
                    const greyHex = grey.toString(16).padStart(2, '0');
                    return `#${greyHex}${greyHex}${greyHex}`;
                }
            };
            let getBackgroundColors;
            if (comparingLandtypeSelectedOption === "none" || comparingLandtypeSelectedOption === "" || comparingLandtypeSelectedOption === undefined || comparingLandtypeSelectedOption === null) {
                getBackgroundColors = (data, selectedOption) => data.labels.map(
                    label => label === selectedOption ? highlightColor : presetColors[label]
                );
            } else {
                getBackgroundColors = (data, selectedOption) => data.labels.map(
                    label => label === selectedOption ? presetColors[label] : toGreyscale(presetColors[label])
                );
            }
            const backgroundColors1 = getBackgroundColors(data1, comparingLandtypeSelectedOption);
            const backgroundColors2 = getBackgroundColors(data2, comparingLandtypeSelectedOption);

            const datasets = {
                labels: data1.labels,
                datasets: [
                    {
                        label: 'Base Forecast',
                        data: data2.data,
                        backgroundColor: backgroundColors2,
                        barPercentage: .6, // Increase bar width to make bars closer
                        categoryPercentage: 0.4 // Decrease spacing between categories
                    },
                    {
                        label: 'My Forecast',
                        data: data1.data,
                        backgroundColor: backgroundColors1,
                        barPercentage: .6, // Increase bar width to make bars closer
                        categoryPercentage: 0.4 // Decrease spacing between categories
                    },
                    // Uncomment and configure this if needed
                    // {
                    //     data: data3.data,
                    //     label: 'Benchmarking',
                    //     type: 'scatter',
                    //     borderColor: 'rgba(0, 0, 0, .6)',
                    //     fill: false,
                    //     pointStyle: 'circle',
                    //     pointRadius: 5, // Adjust as needed
                    //     borderColor: 'rgba(0, 0, 0, 0)',
                    //     pointBackgroundColor: backgroundColors2,
                    // }
                ]
            };
            initChart(chartRef, 'chart', datasets);
        }
    }, [longstandingGeoJSONRef.current, selectedMetric, selectedYearEditing, comparingLandtypeSelectedOption]);


    useEffect(() => {
        console.log(comparingLandtypeSelectedOption);
        if (longstandingGeoJSONRef.current) {
            let cumulativeData;

            if (comparingLandtypeSelectedOption === "none" || comparingLandtypeSelectedOption === "" || comparingLandtypeSelectedOption === undefined || comparingLandtypeSelectedOption === null) {
                cumulativeData = getCumulativeDataOverYearsAll(longstandingGeoJSONRef.current);
                console.log(cumulativeData)
            } else {
                cumulativeData = getCumulativeDataOverYears(longstandingGeoJSONRef.current, comparingLandtypeSelectedOption);
            }

            const datasets = {
                labels: years.map(year => year.split('T')[0]),
                datasets: [
                    {
                        label: 'Total',
                        data: cumulativeData,
                        borderColor: presetColors[comparingLandtypeSelectedOption],
                        backgroundColor: presetColors[comparingLandtypeSelectedOption],
                        fill: true
                    }
                ]
            };

            initChartline(lineChartRef, 'line-chart', datasets);
        }
    }, [longstandingGeoJSONRef.current, selectedMetric, selectedYearEditing, comparingLandtypeSelectedOption]);

    useEffect(() => {
        let chart = chartRef.current;
        // Function to get the X position of the highlighted bar on the chart
        const getHighlightedBarXPosition = (chartRef, comparingLandtypeSelectedOption) => {
            // Get the chart's dataset
            const dataset = chart.data.datasets[0]; // Assuming you are working with the first dataset
            // Find the index of the selected option in the chart's labels
            const highlightedIndex = chart.data.labels.indexOf(comparingLandtypeSelectedOption);
            // Return null if the selected option is not found
            if (highlightedIndex === -1) {
                return null;
            }
            // Find the X position of the highlighted bar
            const meta = chart.getDatasetMeta(0); // Assuming you are working with the first dataset
            const dataPoint = meta.data[highlightedIndex];
            // Return the X position of the highlighted bar
            return dataPoint ? dataPoint.x : null;
        };
        const xPosition = getHighlightedBarXPosition(chart, comparingLandtypeSelectedOption);
        let chart_1_hover_information = document.querySelector('.chart_1_hover_information');
        // Function to show the hover information
        const showHoverInformation = (chart_1_hover_information, xPosition, comparingLandtypeSelectedOption) => {
            // Show the hover information
            chart_1_hover_information.style.display = 'block';
            chart_1_hover_information.style.left = `${xPosition}px`;
            chart_1_hover_information.innerHTML = comparingLandtypeSelectedOption;
            // Get the data for the selected option
            let coredata = chart.data.datasets[0].data[chart.data.labels.indexOf(comparingLandtypeSelectedOption)]
            if (coredata == undefined) {
                coredata = 0;
            } else {
                coredata = coredata.toFixed(2);
            }
            // Append the data to the hover information
            chart_1_hover_information.innerHTML += `<br>Forecast: ${coredata}<br>`;
            // Get the data for the selected option
            let userdata = chart.data.datasets[1].data[chart.data.labels.indexOf(comparingLandtypeSelectedOption)];
            if (userdata == undefined) {
                userdata = 0;
            } else {
                userdata = userdata.toFixed(2);
            }
            // Append the data to the hover information
            chart_1_hover_information.innerHTML += `Original: ${userdata}<br>`;
            // Get the data for the selected option
            // let benchmarkdata = chart.data.datasets[2].data[chart.data.labels.indexOf(comparingLandtypeSelectedOption)];
            // if (benchmarkdata == undefined) {
            //     benchmarkdata = 0;
            // } else {
            //     benchmarkdata = benchmarkdata.toFixed(2);
            // }
            // // Append the data to the hover information
            // chart_1_hover_information.innerHTML += `Benchmark: ${benchmarkdata}`;
        };
        // Function to hide the hover information
        const hideHoverInformation = (chart_1_hover_information) => {
            // Hide the hover information
            chart_1_hover_information.style.display = 'none';
        };
        if (comparingLandtypeSelectedOption == "none" || comparingLandtypeSelectedOption == "" || comparingLandtypeSelectedOption == undefined || comparingLandtypeSelectedOption == null) {
            hideHoverInformation(chart_1_hover_information);
            return;
        }
        // Show the hover information
        showHoverInformation(chart_1_hover_information, xPosition, comparingLandtypeSelectedOption);
        // 
    }, [longstandingGeoJSONRef.current, comparingLandtypeSelectedOption, chartRef.current]);

    let displayMetric;

    if (selectedMetric === "ParcelCarbon") {
        displayMetric = "Levels of Carbon Stock";
    } else if (selectedMetric === "ParcelCarbonFlux") {
        displayMetric = "Levels of Parcel Carbon Flux";
    } else if (selectedMetric === "Value_GBP") {
        displayMetric = "Value (GBP)";
    } else if (selectedMetric === "BDUnits_Parcel") {
        displayMetric = "Biodiversity Levels";
    }

    return (
        <div className='compare'>
            <h2>Compare data</h2>
            <div className="compare__container_one">
                <div className='compare__container__content__item__content'>
                    <h2>Current Data Set</h2>
                    <p>Base {[displayMetric]} & Forecast {[displayMetric]} per landtype</p>
                    <canvas id="chart"></canvas>
                    <div className="chart_1_hover_information">

                    </div>
                    <p className="linechartTitle">{[displayMetric]} Total over years</p>
                    <canvas id="line-chart" className="linechart"></canvas>
                </div>
            </div>
        </div>
    );
}

export default ComparePanel;
