import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const GeneralNav = ({
    globalRefs,
    globalState,
    setIsUKHabColoursActive,
    selectedYearEditing
}) => {
    const [isMapViewActive, setIsMapViewActive] = useState(true);
    const [is2DViewActiveVis, setIs2DViewActiveVis] = useState(false);
    // const [polygonCustomLayerRef, setPolygonCustomLayerRef] = useState(null);

    let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;

    let is2DViewActive = globalState.is2DViewActive;
    let setIs2DViewActive = globalState.setIs2DViewActive;

    const toggle2DView = () => {
        setIs2DViewActive(!is2DViewActive);
        if (is2DViewActive) {
            polygonCustomLayerRef.current.set3DView(selectedYearEditing);
            setIsUKHabColoursActive(false);
        } else {
            polygonCustomLayerRef.current.set2DView();
        }
    };

    useEffect(() => {
        console.log(is2DViewActive)
        setIs2DViewActiveVis(is2DViewActive);
    }, [is2DViewActive]);

    const toggleMapView = (viewType) => {
        setIsMapViewActive(false);
        let mapbase = 'Map';
        polygonCustomLayerRef.current.swapMapBase(mapbase);
    };

    const toggleSatView = (viewType) => {
        setIsMapViewActive(true);
        let mapbase = 'Satellite';
        polygonCustomLayerRef.current.swapMapBase(mapbase);
    };

    const mobileStatus = isMobile ? "mobile" : "not-mobile";

    return (
        <>
            <div className={`general_nav ${mobileStatus}`}>

                <button className="gnav_button" onClick={toggle2DView}>
                    <p>2D View</p>
                    <label className="toggle_switch" >
                        <input type="checkbox" checked={is2DViewActiveVis} />
                        <span className="slider round"></span>
                    </label>
                </button>
                <>
                    <button className="gnav_button" onClick={() => toggleMapView('Map')}>
                        <div className={`gnav_mv_toggle ${isMapViewActive ? '' : 'active'}`}>Map View</div>
                    </button>
                    <button className="gnav_button" onClick={() => toggleSatView('Satellite')}>
                        <div className={`gnav_mv_toggle ${isMapViewActive ? 'active' : ''}`}>Satellite View</div>
                    </button>
                </>
            </div>
        </>
    );
};

export default GeneralNav;
