import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import SkylarkPolygonLayer from '../core/skylarkPolygonLayer';
import { landCoverClasses } from '../../libs/ukhablandtypes.js';
import { center } from '@turf/turf';  // Center function from Turf

import {
  fmesh,
  fullmesh,
  loading_timer_count
} from '../core/globalVariables.js';
import { IoClose } from "react-icons/io5";
import supabaseClient from '../../supabaseClient';
import BarLoader from "react-spinners/BarLoader";

const LoadMenu = ({
  isMenuLoadClick,
  closeLoadMenu,
  files,
  handleItemClick,
  globalRefs,
  globalState,
  ResetMapLoadedData,
  mapLoaded,
  loggedInToAPI,
  setLoggedInToAPI,
  ResetPolygonLayer,
  selectedMetric,
  setSelectedMetric,
  setLoadedPercentage,
  ResetPolygonLayerPublic,
  ResetMapInit
}) => {

  const [mapData, setMapData] = useState([]);
  const [mapDetails, setMapDetails] = useState(null);
  const [clickedMapId, setClickedMapId] = useState(null);
  const [initFetch, setInitFetch] = useState(0);
  const [isLoadingThumbnails, setIsLoadingThumbnails] = useState(false);

  const setIsLoading = globalState.setIsLoading;
  const mapRef = globalRefs.mapRef;
  const polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  const longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  const originalGeoJSONforComparingRef = globalRefs.originalGeoJSONforComparingRef;
  const setisMenuLoadClick = globalState.setisMenuLoadClick;
  let centroidRef = globalRefs.centroidRef;
  const setCurrentMapId = globalState.setCurrentMapId;
  let uniqueTextureNamesArray = globalState.uniqueTextureNamesArray;
  let setUniqueTextureNamesArray = globalState.setUniqueTextureNamesArray;
  let workingLocally = globalState.workingLocally;
  let setWorkingLocally = globalState.setWorkingLocally;
  let userEmail = globalState.userEmail;
  let setUserEmail = globalState.setUserEmail;

  const uniqueTextureNames = new Set();

  const getUserInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (!token) {
        throw new Error('No token in local storage');
      } else {
        console.log("we have a token for user data retrieval: " + token);
      }
      if (workingLocally) {
        // Make sure the URL is correct
        response = await axios.get(`/api/info`, { withCredentials: true });
        console.log(response.data.user.email);
        setUserEmail(response.data.user.email);
      } else {
        // LIVE - - - - - - - - 
        response = await fetch(`https://harrierapi9697.site/info/?token=${encodeURIComponent(token)}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        console.log(data);
        setUserEmail(data.user.email);
      }
      // const data = await response.json();
      // console.log('User info:', data);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const getMapThumbnails = async (maps) => {
    try {
      // Fetch thumbnails for all maps
      const thumbnails = await Promise.all(
        maps.map(async (map) => {
          const { data, error } = await supabaseClient
            .from('images') // Replace with your actual table name
            .select('base64') // Replace with your actual column name
            .eq('name', map.id) // Adjust the condition if necessary
            .single();
          if (error) {
            // console.error(`Error fetching thumbnail for map ${map.id}:`, error);
            return { id: map.id, thumbnail: null };
          }
          // Ensure that base64 data exists before attempting to clean it
          if (data && data.base64) {
            // Clean up the base64 data by removing any duplicate data URI prefix
            const cleanBase64 = data.base64.replace(/^(data:image\/[a-zA-Z]+;base64,)+/, 'data:image/png;base64,');
            return { id: map.id, thumbnail: cleanBase64 };
          } else {
            // Handle the case where base64 data is null or undefined
            return { id: map.id, thumbnail: null };
          }
        })
      );
      // Update the state with the fetched thumbnails
      setMapDetails((prevMapDetails) => ({
        ...prevMapDetails,
        thumbnails: thumbnails.reduce((acc, { id, thumbnail }) => {
          acc[id] = thumbnail;
          return acc;
        }, {})
      }));
      console.log('Finished fetching thumbnails'); // Log when finished
      setIsLoadingThumbnails(false); // Reset
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  useEffect(() => {
    if (isMenuLoadClick === true) {
      setIsLoadingThumbnails(true); // Set
      const fetchMapData = async () => {
        try {
          const token = localStorage.getItem('token');
          console.log(token);
          let response;
          // LOCAL - - - - - - - - 
          if (workingLocally) {
            response = await axios.get('/api/map', { withCredentials: true });
          } else {
            // LIVE - - - - - - - - 
            response = await fetch(`https://harrierapi9697.site/map/?token=${encodeURIComponent(token)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
          }
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            for (let i = 0; i < data.length; i++) {
              if (data[i].id === "f210dd5d-4cd7-48aa-8a32-278c51d1d052") {
                data.splice(i, 1);
              }
              if (data[i].id === "2fcbd066-578f-421a-9676-b0b50eddcea1") {
                data.splice(i, 1);
              }
            }
            setMapData(data);
            getMapThumbnails(data);
          } else {
            console.error('Network response was not ok.');
          }
        } catch (error) {
          console.error('Error fetching map data:', error);
        }
      };
      console.log('fetching map data');
      fetchMapData();
    }
  }, [isMenuLoadClick]);

  const fetchMapDetailsSelected = async (id) => {
    setClickedMapId(id);
  };

  const loadDataBtn = () => {
    fetchMapDetails(clickedMapId);
    setisMenuLoadClick(false);
    setIsLoading(true);
  };

  let i = 0;

  const fetchMapDetails = async (id) => {
    setCurrentMapId(id);
    setisMenuLoadClick(false);
    setLoadedPercentage(40);
    setTimeout(() => {
      setLoadedPercentage(60);
    }, 2500);
    setTimeout(() => {
      setLoadedPercentage(80);
    }, 5000);
    setTimeout(() => {
      setLoadedPercentage(100);
    }, 7500);
    setLoggedInToAPI(true);

    // Try to access data through the API

    async function fetchMapDetails(mapId) {
      try {
        const token = localStorage.getItem('token');
        console.log(token);
        let response;
        let geoJsonData;
        if (!token) {
          throw new Error('No token in local storage');
        } else {
          console.log("we have a token: " + token);
          // LOCAL
          if (workingLocally) {
            response = await axios.get(`/api/map/${id}`, { withCredentials: true });
          } else {
            // LIVE
            response = await fetch(`https://harrierapi9697.site/map/${id}?token=${encodeURIComponent(token)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
          }
          // LOCAL
          if (workingLocally) {
            geoJsonData = response.data;
            console.log(geoJsonData)
          } else {
            // LIVE
            if (!response.ok) {
              throw new Error(`Error fetching map details: ${response.statusText}`);
            }
            geoJsonData = await response.json();
          }
          loggedInDataLoad(geoJsonData);
          getUserInfo();
        }
      } catch (error) {
        console.error('Error:', error);
        loadPublicData();
      }
    }
    fetchMapDetails(id);
  };

  // Load private data if the user is logged in

  const loggedInDataLoad = async (geoJsonData) => {
    console.log(geoJsonData);
    centroidRef.current = center(geoJsonData);
    setMapDetails(geoJsonData);
    longstandingGeoJSONRef.current = geoJsonData;
    longstandingGeoJSONRef.current.centroidRef = centroidRef.current.geometry.coordinates;
    let newDataArray = [];
    let invalidFeatures = [];
    if (i == 0) {
      originalGeoJSONforComparingRef.current = JSON.parse(JSON.stringify(geoJsonData));
      i = 1;
    }
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      let feature = { ...longstandingGeoJSONRef.current.features[i] };
      delete feature.type;
      const coordinates = feature.geometry.coordinates[0];
      if (coordinates.length < 4) {
        invalidFeatures.push(feature);
      } else {
        newDataArray.push(feature);
      }
    }
    longstandingGeoJSONRef.current.features = newDataArray;
    console.log("Invalid features:", invalidFeatures);
    for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
      const UKHABHabitatType = longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType;
      uniqueTextureNames.add(UKHABHabitatType);
    }
    setUniqueTextureNamesArray([...uniqueTextureNames]);
    setLoadedPercentage(60);
    if (mapRef.current) {
      if (initFetch === 0) {
        polygonCustomLayerRef.current = new SkylarkPolygonLayer(
          'Polygons',
          centroidRef.current.geometry.coordinates[1],
          centroidRef.current.geometry.coordinates[0],
          longstandingGeoJSONRef.current,
          globalRefs,
          globalState,
          true
        );
        mapRef.current.addLayer(polygonCustomLayerRef.current);
        ResetMapInit(globalRefs, globalState);
      } else {
        fmesh.children.forEach((child) => {
          child.geometry.dispose();
          child.material.dispose();
        });
        fullmesh.children.forEach((child) => {
          fullmesh.remove(child);
        });
        fmesh.children.forEach((child) => {
          fmesh.remove(child);
        });
        fmesh.children.length = 0;
        fullmesh.children.length = 0;
        mapRef.current.removeLayer('Polygons');
        polygonCustomLayerRef.current = new SkylarkPolygonLayer(
          'Polygons',
          centroidRef.current.geometry.coordinates[1],
          centroidRef.current.geometry.coordinates[0],
          longstandingGeoJSONRef.current,
          globalRefs,
          globalState,
          true
        );
        mapRef.current.addLayer(polygonCustomLayerRef.current);
        ResetMapInit(globalRefs, globalState);
      }
      mapRef.current.on('mousedown', (e) => {
        if (e.originalEvent.button === 0) {
          polygonCustomLayerRef.current.raycast(e.point, false);
        }
      });
      mapRef.current.on("move", () => {
        const camera = mapRef.current.getFreeCameraOptions();
        const cameraPosition = camera._position.toLngLat();
        polygonCustomLayerRef.current.moveDirectionalLight(cameraPosition);
      });
      mapRef.current.flyTo({
        center: centroidRef.current.geometry.coordinates,
        zoom: 13,
        speed: 3, // Speed of the fly animation
      });
    }
  }

  // Load public data if the user is not logged in

  const loadPublicData = async () => {
    setLoggedInToAPI(false);
    try {
      const response = await axios.get('/data/modified_geojson-8.json');
      setLoadedPercentage(60);
      const geoJsonData = response.data;
      centroidRef.current = center(geoJsonData);
      longstandingGeoJSONRef.current = geoJsonData;
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let feature = { ...longstandingGeoJSONRef.current.features[i] };
        const coordinates = feature.geometry.coordinates[0][0];
        feature.geometry.coordinates[0] = coordinates;
      }
      polygonCustomLayerRef.current = new SkylarkPolygonLayer(
        'Polygons',
        centroidRef.current.geometry.coordinates[1],
        centroidRef.current.geometry.coordinates[0],
        geoJsonData,
        globalRefs,
        globalState,
        false
      );
      let newDataArray = [];
      let invalidFeatures = [];
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let feature = { ...longstandingGeoJSONRef.current.features[i] };
        delete feature.type;
        const coordinates = feature.geometry.coordinates[0]; // Assuming coordinates are already nested correctly
        // Check if the feature has fewer than 4 coordinate pairs
        if (coordinates.length < 4) {
          invalidFeatures.push(feature);
        } else {
          // Otherwise, process the feature as usual
          newDataArray.push(feature);
        }
      }
      // Update the longstandingGeoJSONRef with valid features only
      longstandingGeoJSONRef.current.features = newDataArray;
      console.log("Invalid features:", invalidFeatures);
      for (let i = 0; i < longstandingGeoJSONRef.current.features.length; i++) {
        let UKHABHabitatType = longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType;
        longstandingGeoJSONRef.current.features[i].feature_data_simple.HabitatType = UKHABHabitatType.toLowerCase();
        UKHABHabitatType = UKHABHabitatType.toLowerCase();
        uniqueTextureNames.add(UKHABHabitatType);
      }
      setUniqueTextureNamesArray([...uniqueTextureNames]);
      if (mapRef.current) {
        mapRef.current.addLayer(polygonCustomLayerRef.current);
        mapRef.current.on('mousedown', (e) => {
          if (e.originalEvent.button === 0) {
            polygonCustomLayerRef.current.raycast(e.point, false);
          }
        });
      }
      mapRef.current.on("move", () => {
        const camera = mapRef.current.getFreeCameraOptions();
        const cameraPosition = camera._position.toLngLat();
        polygonCustomLayerRef.current.moveDirectionalLight(cameraPosition);
      });
      mapRef.current.flyTo({
        center: [-0.9061603891426653, 54.121878725877096],
        zoom: 5,
        speed: 1, // Speed of the fly animation
      });
    } catch (error) {
      console.error('Error loading public data:', error);
    }
  };

  // Reset the map data when the user logs out

  useEffect(() => {
    if (mapData && initFetch === 0 && mapLoaded) {
      fetchMapDetails('4b1ccff7-9242-41a6-8bda-6684afadaf1e');
      setInitFetch(1);
    }
  }, [mapData, initFetch, mapLoaded]);

  return (
    isMenuLoadClick ? (
      <div className='file_dropdown_list'>
        <div className='APIMapLoad'>
          <div className="closeBtnCont">
            <span className="closeLoadBtn" onClick={closeLoadMenu}>
              Cancel
            </span>
            <span className="openLoadBtn" onClick={loadDataBtn}>
              Open
            </span>
          </div>
          <div className='APIMapLoadInnter'>
            {isLoadingThumbnails ? (
              <div className="loadingThumbnails">
                Loading Maps
                <div className="loadingThumbnailsSpinner">
                  <BarLoader />
                </div>
              </div>
            ) : (
              // - - - - - - - - - - -
              <ul className='map-list'>
                {(() => {
                  // Find the index of the map with the name "3007 1332"
                  const startIndex = mapData.findIndex(map => map.name === "3007 1332");

                  // Filter maps to include only those after the identified index
                  const filteredMapData = startIndex !== -1 ? mapData.slice(startIndex + 1) : mapData;

                  return filteredMapData.map((map, index) => (
                    <li
                      key={index}
                      onClick={() => fetchMapDetailsSelected(map.id)}
                      className={`map-item ${clickedMapId === map.id ? 'highlighted' : ''}`}
                    >
                      <div className='map-list-info-cont'>
                        <div className="LoadMenuscreenshot">
                          {mapDetails.thumbnails && mapDetails.thumbnails[map.id] ? (
                            <img
                              src={`${mapDetails.thumbnails[map.id]}`}
                              alt={map.title}
                              className='thumbnail'
                            />
                          ) : (
                            <div className="placeholder-thumbnail">No Image</div>
                          )}
                        </div>
                        <div className='map-item-name'>
                          <span className='map-name'>{map.title}</span>
                        </div>
                      </div>
                    </li>
                  ));
                })()}
              </ul>
              // - - - - - - - - - - -
            )
            }
          </div>
        </div>
      </div>
    ) : null
  )
};

export default LoadMenu;
