import {
  fmesh,
  fullmesh,
  loading_timer_count
} from './globalVariables.js';
import SkylarkPolygonLayer from './skylarkPolygonLayer.js';

const ResetMapMetric = async (globalRefs, globalState) => {
  let centroidRef = globalRefs.centroidRef;
  let setLoadedPercentage = globalState.setLoadedPercentage;
  let mapRef = globalRefs.mapRef;
  let drawRef = globalRefs.drawRef;
  let polygonCustomLayerRef = globalRefs.polygonCustomLayerRef;
  let longstandingGeoJSONRef = globalRefs.longstandingGeoJSONRef;
  let selectedYearEditing = globalState.selectedYearEditing;
  let isDrawing = globalState.isDrawing;
  let isLoggedIntoAPI = globalState.loggedInToAPI;
  let isVisible = globalState.isVisible;
  let setIsVisible = globalState.setIsVisible;
  let is2DViewActive = globalState.is2DViewActive;

  if (isDrawing) {
    mapRef.current.removeControl(drawRef.current);
    mapRef.current.off('draw.create');
    mapRef.current.off('draw.delete');
  }

  setLoadedPercentage(100);

  setTimeout(() => {
    fmesh.children.forEach((child) => {
      child.geometry.dispose();
      child.material.dispose();
    });
    fullmesh.children.forEach((child) => {
      fullmesh.remove(child);
    });
    fmesh.children.forEach((child) => {
      fmesh.remove(child);
    });
    fmesh.children.length = 0;
    fullmesh.children.length = 0;
    mapRef.current.removeLayer('Polygons');
    polygonCustomLayerRef.current = new SkylarkPolygonLayer(
      'Polygons',
      centroidRef.current.geometry.coordinates[1] + 0.0001,
      centroidRef.current.geometry.coordinates[0],
      longstandingGeoJSONRef.current,
      globalRefs,
      globalState,
      true
    );
    setIsVisible(false);
    mapRef.current.addLayer(polygonCustomLayerRef.current);
    if(is2DViewActive){
      polygonCustomLayerRef.current.set2DView();
    }
  }, loading_timer_count);
}

export default ResetMapMetric;